const transferEl = (container, element, prepend) => {
    let block = document.querySelector(container),
        el = document.querySelector(element);

    prepend ? block.prepend(el) : block.append(el)
};

let headerNavLList = document.querySelectorAll('.header-nav__list');
let popularProjectContainer = document.querySelectorAll('.popular-project_container');

if (window.matchMedia("(max-width: 768px)").matches && headerNavLList.length) {
    transferEl('.header-nav__list', '.header-contacts',false);
    transferEl('.header-nav__list', '.social-network_list', false);
    // transferEl('.about__right', '.about__image')
}

if (window.matchMedia("(max-width: 1024px)").matches && popularProjectContainer.length) {
    transferEl('.popular-project_container', '.popular-project_top', true);
    document.querySelector('.popular-project_top').classList.remove('popular-project_item');
}
