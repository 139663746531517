var aboutCommand = new Swiper('.about-command-slider__wrapper', {
    slidesPerView: 1,
    spaceBetween: 32,
    navigation: {
        nextEl: '.about-command__slider-arrow_next',
        prevEl: '.about-command__slider-arrow_prev',
    },
    breakpoints: {
        1340: {
            slidesPerView: 4
        },
        1024:{
            slidesPerView: 3
        },
        780:{
            slidesPerView: 2
        },
        640:{
            slidesPerView: 1
        }
    }
});