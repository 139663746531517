import controlLengthSlider from "./controlLengthSlider";

const container = document.querySelector('.partners__slider');

if (container) {
    const partnersSlider = new Swiper(container, {
        speed: 400,
        navigation: {
            nextEl: '.swiper-button-next.partners__arrow',
            prevEl: '.swiper-button-prev.partners__arrow',
        },
        breakpoints: {
            1025: {
                spaceBetween: 40,
                slidesPerView: 6,
            },
            768:{
                spaceBetween: 40,
                slidesPerView: 5
            },
            600:{
                spaceBetween: 40,
                slidesPerView: 4
            },
            320:{
                spaceBetween: 40,
                slidesPerView: 3
            }
        }
    });

    controlLengthSlider(partnersSlider)
}
