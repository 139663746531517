window.initTweenAnimationsCard = function () {

    $('.card-steps-tabs__toggle-title').each(function () {
        var parent = $(this).parent();
        var target = $(this).siblings('.card-steps-tabs__toggle-wrapper');



        $(this).click(function () {
            $('.card-steps-tabs__toggle-title').parent().removeClass('card-steps-tabs__toggle_open');
            $('.card-steps-tabs__toggle-wrapper').removeClass('is-open');
            parent.addClass('card-steps-tabs__toggle_open');
            target.addClass('is-open');
            console.log($(this));
        })

    });
};

$(function () {
    window.initTweenAnimationsCard();
});
