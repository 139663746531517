
import controlLengthSlider from "./controlLengthSlider";

var catalogMainSliderThumb = new Swiper('.catalog-main-slider-thumb__wrapper', {
    spaceBetween: 2,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
        980: {
            slidesPerView: 5
        },
        768:{
            slidesPerView: 4
        }
    }
});
var catalogMainSlider = new Swiper('.catalog-main-slider__wrapper', {
    autoplay: true,
    navigation: {
        nextEl: '.catalog-main-slider__next',
        prevEl: '.catalog-main-slider__prev',
    },
    thumbs: {
        swiper: catalogMainSliderThumb
    }
});

(()=> {
    const slider = document.querySelector('.catalog-main-slider__wrapper');
    const slides = document.querySelectorAll('.catalog-main-slider-item');

    if (slider && window.matchMedia('(min-width: 1024px)').matches) {
        const wrapper = slider.querySelector('.swiper-wrapper');
        [].forEach.call(slides, function(slide) {
            slide.style.height = "";
        });

        setTimeout(() => {
            [].forEach.call(slides, function(slide) {
                slide.style.height = wrapper.clientHeight + "px";
            });
        }, 300);
    }
})();
