let containerSlide = document.querySelector('.services-benefit__main-wrapper');
let wrapper = document.querySelector('.services-benefit__toggles-wrapper');
let items = document.querySelectorAll('.services-benefit-item_unit');
let cancelItems = document.querySelectorAll('.services-benefit-tab__title');
let tabs = document.querySelectorAll('.services-benefit-tab');
let toggle = document.querySelectorAll('.services-benefit-item');
let container = document.querySelector('.services-benefit__units');

let content = document.querySelector('.services-benefit-aside__item_content');
let main = document.querySelector('.services-benefit');




const sliderBody = new Swiper('.services-benefit__toggles', {
    slidesPerView: 2,
    spaceBetween: 27,
    navigation: {
        nextEl: '.services-benefit__toggles__slider-arrow_next',
        prevEl: '.services-benefit__toggles__slider-arrow_prev',
    },
    breakpoints: {
        780: {
            slidesPerView: 5,
        },
        640: {
            slidesPerView: 4,
        },
        480: {
            slidesPerView: 3,
        }
    }
});

items.forEach((item, index) => {
    item.addEventListener('click', () => {
        containerSlide.classList.add('services-benefit__main-wrapper_visible');
        container.classList.remove('services-benefit__units_visible');
        main.classList.add('services-benefit_active');
        toggle[index].click ();
    })
});

cancelItems.forEach((item) => {
    item.addEventListener('click', () => {
        containerSlide.classList.remove('services-benefit__main-wrapper_visible');
        container.classList.add('services-benefit__units_visible');
        main.classList.remove('services-benefit_active');
    })
});
