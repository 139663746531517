(() => {
    if (window.matchMedia('(min-width: 1025px)').matches) {
        const controller = new ScrollMagic.Controller();
        const elementsCatalog = document.querySelectorAll('.catalog-item');
        const elemTweenToLeft = document.querySelectorAll('.tweenToLeft');
        const sections = document.querySelectorAll('main > section');
        const heightDoc = document.documentElement.clientHeight;
        const indexTopSection = document.querySelector('.index-top');
        const customEasy = "M0,0 C0,0 0.1292,0.03466 0.19933,0.06631 0.24391,0.08644 0.27621,0.10538 0.31057,0.14019 0.42992,0.26112 0.50316,0.34766 0.61641,0.4889 0.77244,0.68348 1,1 1,1 ";


        const catalogBackText$ = (triggerElement, duration, setTweenEl, values) => {
            if (triggerElement) {
                new ScrollMagic.Scene({triggerElement: triggerElement, duration: duration})
                    .setTween(setTweenEl, values)
                    .addTo(controller);
            }
        };

        if (elementsCatalog) {
            elementsCatalog.forEach(el => {
                const setToggleEl = el.querySelector('.catalog-item__image');

                if (setToggleEl) {
                    const setToggleElClass = setToggleEl.classList[1];

                    new ScrollMagic.Scene({triggerElement: el, triggerHook: '0.7'})
                        .setClassToggle(`.${setToggleElClass}`, "__active")
                        .reverse(false)
                        .addTo(controller);
                }
            });
        }

        if (elemTweenToLeft) {
            elemTweenToLeft.forEach(el => catalogBackText$(el, 600, el, {right: 450}));
        }
        catalogBackText$(`.tweenToRight`, 600, `.tweenToRight`, {left: 500});

        if (sections.length) {
            sections.forEach((s, i) => {

                const fadeTween = () => new TimelineMax().to(s, 5, { ease: 'power1.in', y: -200});

                const fTween = i !== 0 && i !== sections.length - 1  ? fadeTween() : false;

                const fadeAnimation$ = new ScrollMagic.Scene({
                    triggerElement: s,
                    triggerHook: .6,
                    duration: heightDoc,
                })
                    .setTween(fTween)
                    .addTo(controller)
            });
        }

        if (indexTopSection) {
            const tweenTopPage = new TimelineMax().to(indexTopSection, 5,{ease: 'power1.in', y: -200});

            const topSectionAnimation$ = new ScrollMagic.Scene({
                triggerElement: indexTopSection,
                triggerHook: .9,
                offset: heightDoc,
                duration: heightDoc
            })
                .setTween(tweenTopPage)
                .addTo(controller)
        }
    }
})();