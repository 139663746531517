const controlLengthSlider = (slider) => {
    if (slider) {
        const sliderLength = slider.slides.length;
        const slidesPerView = slider.params.slidesPerView;
        if (sliderLength <= slidesPerView) {
            slider.destroy(false, false);
            slider.navigation.nextEl.style.visibility = 'hidden';
            slider.navigation.prevEl.style.visibility = 'hidden';
        }
    }
};

export default controlLengthSlider;