const burgerMenu = () => {
    let burgerBtn = document.querySelector('.header-burger');
    let headerNav= document.querySelector('.header-nav');

    if (burgerBtn && headerNav) {
        burgerBtn.addEventListener('click', () => {
            burgerBtn.classList.toggle('_open');
            headerNav.classList.toggle('_open');
        });
    }
};

burgerMenu();