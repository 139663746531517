(() => {
    const header = document.querySelector('.header');
    const body = document.querySelector('body');

    window.addEventListener('scroll', (e) => {
        let scrollTop = document.documentElement.scrollTop || document.body && document.body.scrollTop || 0;
        scrollTop -= document.documentElement.clientTop;

        header && scrollTop > 0 ? header.classList.add('header_fixed') : header.classList.remove('header_fixed');
    })
})();
