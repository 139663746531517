const ieCheck = () => {
    let ua = navigator.userAgent;
    return !!(ua.match(/rv:11.0/i))
};

if(ieCheck()){
    const list = document.querySelector('.catalog-index');
    if (list) {
        list.classList.add('__ie');
        console.info('__ie');
    }
}
