import { AnimationSlider } from './animationSlider'
import controlLengthSlider from "./controlLengthSlider";

const newAnimationSlider = new AnimationSlider('.header-slider');
const container = document.querySelector('.header-slider_container');

if (container) {
    const headerSlider = new Swiper(container, {
        speed: 1300,
        effect: 'fade',
        autoplay: {
            delay: 4000,
        },
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next.header-slider-arrow',
            prevEl: '.swiper-button-prev.header-slider-arrow',
        },
        pagination: {
            el: '.header-slider_pagination',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                if(total < 10){
                    return `<span class="current">0${current}</span> / <span class="total">0${total}</span>`;
                }else{
                    return `<span class="current">${current}</span> / <span class="total">${total}</span>`;
                }
            }
        },
        on: {
            slideChangeTransitionStart: () => newAnimationSlider.open(),
            transitionEnd: () => newAnimationSlider.close()
        }
    });

    controlLengthSlider(headerSlider)
}


