import controlLengthSlider from "./controlLengthSlider";

var cardMore = new Swiper('.catalog-more-slider__wrapper', {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    navigation: {
        nextEl: '.catalog-more__slider-arrow_next',
        prevEl: '.catalog-more__slider-arrow_prev',
    },
    breakpoints: {
        1340: {
            spaceBetween: 45,
            slidesPerView: 4
        },
        1140:{
            slidesPerView: 3
        },
        1024:{
            slidesPerView: 3
        },
        780:{
            spaceBetween: 20,
            slidesPerView: 3
        },
        640:{
            slidesPerView: 2
        },
        480:{
            slidesPerView: 2
        }
    }
});