import controlLengthSlider from "./controlLengthSlider";

const container = document.querySelector('.our-works__slider');

if (container) {
    const ourWorksSlider = new Swiper(container, {
        loop: true,
        speed: 400,
        navigation: {
            nextEl: '.swiper-button-next.our-works__slider-arrow',
            prevEl: '.swiper-button-prev.our-works__slider-arrow',
        },
        slideNextClass: 'our-works__next-slide',
        breakpoints: {
            1367: {
                centeredSlides: true,
                spaceBetween: 0,
                slidesPerView: 3,
            },
            769: {
                centeredSlides: false,
                spaceBetween: 40,
                slidesPerView: 3,
            },
            450: {
                centeredSlides: false,
                spaceBetween: 20,
                slidesPerView: 2,
            }
        }
    });
}