import './polyfill.js'
import './popularProject';
import './append';
import './burgerMenu';
import './fixedHeader';
import './catalog';
// import './map';
import './popup';
import './inputmask';
import './sliders/animationSlider';
import './sliders/sliderTop';
import './sliders/reviews';
import './sliders/certificates';
import './sliders/ourWorks';
import './sliders/partners';
import './sliders/popular';
import './sliders/stocks';
import './sliders/cardMAinSlider';
import './sliders/cardPrice';
import './sliders/cardMore';
import './sliders/benefit';
import './sliders/services';
import './sliders/aboutCommand';
import './scrollAnimation';
import './smoothScroll';

import './catalog-detail';
import './card';

import initTabs from './tabs';

(()=> {
    initTabs('.card-tabs');
    initTabs('.card-price-tabs');
    initTabs('.services-benefit__main-wrapper');
})();

