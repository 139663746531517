import controlLengthSlider from "./controlLengthSlider";

const container = document.querySelector('.certificates__slider');

if (container) {
    const certificatesSlider = new Swiper(container, {
        speed: 400,
        navigation: {
            nextEl: '.swiper-button-next.certificates__arrow',
            prevEl: '.swiper-button-prev.certificates__arrow',
        },
        breakpoints: {
            1366: {
                spaceBetween: 120,
                slidesPerView: 3,
            },
            1025: {
                slidesPerView: 3,
                spaceBetween: 60,
            },
            320:{
                slidesPerView: 2
            }
        }
    });
}