export class AnimationSlider {
    constructor(sliderParentClass) {
        if (sliderParentClass) {
            this.slider = document.querySelector(sliderParentClass);
            // this.button = document.querySelector(buttonClass);
            this.activeClass = 'isOpen';
        }
    }

    open() {
        this.slider.classList.add(this.activeClass);
        // this.button.classList.add(this.activeClass);
    }

    close() {
        this.slider.classList.remove(this.activeClass);
        // this.button.classList.remove(this.activeClass);
    }
}

