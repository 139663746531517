import controlLengthSlider from "./controlLengthSlider";

const container = document.querySelector('.stocks__slider');

if (window.matchMedia("(max-width: 1024px)").matches && container){
    const stocksSlider = new Swiper(container, {
        speed: 400,
        spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-button-next.stocks__slider-arrow',
            prevEl: '.swiper-button-prev.stocks__slider-arrow',
        },
        breakpoints: {
            468: {
                slidesPerView: 2,
            },
        }
    });

    controlLengthSlider(stocksSlider)
}
