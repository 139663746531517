import controlLengthSlider from "./controlLengthSlider";

var cardPrice = document.querySelectorAll('.card-price-slider__wrapper')

cardPrice.forEach((item)=>{
    new Swiper(item, {
        slidesPerView: 1,
        navigation: {
            nextEl: '.card-price__slider-arrow_next',
            prevEl: '.card-price__slider-arrow_prev',
        },
        breakpoints: {
            1340: {
                slidesPerView: 6
            },
            1140:{
                slidesPerView: 5
            },
            1024:{
                slidesPerView: 4
            },
            780:{
                slidesPerView: 3
            },
            640:{
                slidesPerView: 2
            }
        }
    });
})
