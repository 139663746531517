import controlLengthSlider from "./controlLengthSlider";

const container = document.querySelector('.reviews__list');

if (container) {
    const reviewsSlider = new Swiper(container, {
        speed: 400,
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next.reviews__arrow',
            prevEl: '.swiper-button-prev.reviews__arrow',
        },
        breakpoints: {
            1025: {
                slidesPerView: 3,
            },
            601: {
                slidesPerView: 2,
            }
        }
    });

    controlLengthSlider(reviewsSlider)
}
