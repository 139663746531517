(() => {
    const popularContainer = document.querySelector('.popular-project_container'),
        popularWrapper = document.querySelector('.popular-project_list'),
        popularItems = document.querySelectorAll('.popular-project_item '),
        swiperContainerClass = 'swiper-container',
        swiperWrapperClass = 'swiper-wrapper',
        swiperSlideClass = 'swiper-slide';

    const addSliderClass = () => {
        if (popularContainer && popularWrapper && popularItems) {
            popularContainer.classList.add(swiperContainerClass);
            popularWrapper.classList.add(swiperWrapperClass);
            popularItems.forEach(item => item.classList.add(swiperSlideClass));
        }
    };

    if (window.matchMedia("(max-width: 1024px)").matches) {
        addSliderClass();
    }


})();
