const serviceSlider = new Swiper('.section-examples-slider__wrapper', {
    slidesPerView: 1,
    spaceBetween: 34,
    navigation: {
        nextEl: '.section-examples__slider-arrow_next',
        prevEl: '.section-examples__slider-arrow_prev',
    },
    breakpoints: {
        1024: {
            slidesPerView: 4,
        },
        780: {
            slidesPerView: 3,
        },
        640: {
            slidesPerView: 2,
        }
    }
});