import controlLengthSlider from "./controlLengthSlider";

const container = document.querySelector('.popular-project_container');

if (window.matchMedia("(max-width: 1024px)").matches && container) {
    const popular = new Swiper(container, {
        grabCursor: true,
        speed: 400,
        spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-button-next.popular-project-arrow',
            prevEl: '.swiper-button-prev.popular-project-arrow',
        },
        breakpoints: {
            601: {
                slidesPerView: 3
            },
            320: {
                slidesPerView: 2,
            }
        }
    });

    controlLengthSlider(popular)
}
