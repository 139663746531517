window.initFilterSlider = function () {
    var slider = $('.js-rangeSlider');
    if (slider.length) {
        var valueMax = slider.data('max');
        var valueMin = slider.data('min');
        var inputMin = $('.js-rangeSliderMinimal');
        var inputMax = $('.js-rangeSliderMaximal');
        var inputs = $('.catalog-detail-aside-filter-range__input');
        slider.slider({
            min: valueMin,
            max: valueMax,
            range: true,
            step: 50,
            values: [inputMin.val(), inputMax.val()],
            stop: function (event, ui) {
                inputMin.val(ui.values[0]);
                inputMax.val(ui.values[1]);
            },
            slide: function (event, ui) {
                inputMin.val(ui.values[0]);
                inputMax.val(ui.values[1]);
            }

        });
        inputs.change(function () {
            var minValue = inputMin.val();
            var maxValue = inputMax.val();
            if (parseInt(minValue) > parseInt(maxValue)) {
                minValue = maxValue;
            }
            if (maxValue > valueMax) {
                maxValue = valueMax;
            }
            if (parseInt(minValue) < parseInt(valueMin)) {
                minValue = valueMin;
            }
            inputMin.val(minValue);
            inputMax.val(maxValue);
            slider.slider('values', [minValue, maxValue]);
        });
    }
};

window.initTweenAnimations = function () {
    if ($(window).width() <= 1024) {
        $('.catalog-detail-aside-filter-item__title').each(function () {
            var parent = $(this).parent();
            var target = $(this).siblings('.catalog-detail-aside-filter-item__content').get(0);

            var tl = new TimelineLite({id: "tweenToggle"});
            tl.to(target, .6, {height: target.scrollHeight});
            tl.to(target, .2, {height: 'auto'});
            tl.pause();
            $(this).data('tweenToggle', tl);
            if (parent.hasClass('catalog-detail-aside-filter-item_open')) {
                parent.addClass('catalog-detail-aside-filter-item_open');
                $(this).data('tweenToggle').play();
            }
            $(this).click(function () {
                if (parent.hasClass('catalog-detail-aside-filter-item_open')) {
                    parent.removeClass('catalog-detail-aside-filter-item_open');
                    $(this).data('tweenToggle').reverse();
                } else {
                    parent.addClass('catalog-detail-aside-filter-item_open');
                    $(this).data('tweenToggle').play();
                }
            })
        });
    }
};


$(function () {
    window.initFilterSlider();
    window.initTweenAnimations();
    console.log(123)
});
